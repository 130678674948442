import axios from "axios";
import PATH_ from '../GLOBAL-VAR/globals-urls';

const PATH__ = new PATH_();

class SearchRequest {
    search(params) {
        return (
            axios({
                method: 'get',
                url: PATH__.api + "search/?" + params,
                headers: {
                    'Content-Type': 'application/json'
                },
            })
        )
    }
}

export default SearchRequest;