import React from 'react';
import { connect } from 'react-redux';

/**----------------------------------------------------------------------------------------*/
/**Importation des composants de la page */
import countryList from 'react-select-country-list';
import ReactCountryFlag from "react-country-flag";
import parse from 'html-react-parser';


/**----------------------------------------------------------------------------------------*/
/** Importation des screens */



/**----------------------------------------------------------------------------------------*/
/**Importation du superScreen*/
import SuperScreen from '../class/SuperScreen';



/**----------------------------------------------------------------------------------------*/
/**Importation des styles de la page */
import './styles/search_view.style.scss';
import './responsive.style/home.responsive.style.scss';




/**----------------------------------------------------------------------------------------*/
/**Importation des images*/



import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user : state.User.user,
    };
}

class SearchView extends SuperScreen {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            search_value: "",
            current_filter: 0,
            country: "",
            search_result: [],
        }
        this.accessStore = new AccessStore( this.props.dispatch);
        this.categories = [
            {
                name: "Arrêtés"
            },{
                name: "Traités"
            },{
                name: "Pactes"
            },{
                name: "Accords internationnaux"
            },{
                name: "Décret"
            },{
                name: "Ammendement"
            },{
                name: "Constitutions"
            }, {
                name: "Juriseprudence"
            }
        ]
    }

    componentDidMount() {
        this.query = new URLSearchParams(window.location.search);
        let search_value =  decodeURIComponent(this.query.get("search"));
        let country_value =  decodeURIComponent(this.query.get("country"));

        this.setState({
            search_value: search_value,
            country: country_value === "all" ? "" : country_value
        });

        if(country_value === "all") {
            let requestParams = "search=" + search_value;
            this._Search(requestParams, (Response) => {
                // console.log(Response.data);
                if(Response.data && Response.data.length > 0) {
                    this.setState({
                        search_result:Response.data
                    });
                }
            }, (e) => {
                console.log(e);
            });
        }
    }
   
    render() {
        return (
            <>
                {
                    super.render()
                }
                <div className='search_container'>
                    <form className='search_container_head' method='GET' action='/search:' role='search'>
                        <div className='search_container_head_'>
                            <div className='search_container_head_logo'>
                                <a href='./'>
                                    <img src={this.logo} alt='codelegg'/>
                                </a>
                            </div>

                            <div className='search_container_head_input'>
                                <div className='search_container_head_input_balise'>
                                    <input  
                                        jsaction="paste:puy29d;" 
                                        id="APjFqb" 
                                        maxLength="2048" 
                                        name="search"
                                        rows="1" 
                                        aria-activedescendant="" 
                                        aria-autocomplete="both" 
                                        aria-controls="Alh6id" 
                                        aria-expanded="false"  
                                        aria-owns="Alh6id"
                                        autoCapitalize="off"
                                        autoComplete="off" 
                                        autoCorrect="off"
                                        role="combobox"
                                        spellCheck="false" 
                                        title="Rechercher" 
                                        type="search" 
                                        aria-label="Rech." 
                                        data-ved="0ahUKEwjRkpuS4rD-AhXOTaQEHbj9CswQ39UDCAQ"
                                        value={this.state.search_value}

                                        onChange={(e) => {
                                            this.setState({
                                                search_value: e.target.value
                                            })
                                        }}
                                    />
                                </div>

                                <div className='search_container_head_input_button'>
                                    <button title='Rechercher'>
                                        <svg fill="currentColor" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path></svg>
                                    </button>
                                </div>
                            </div>

                            <div className='search_container_head_select'>
                                <select name='country' className='button-style-2' value={this.state.country}
                                    onChange={(e) => {
                                        this.setState({
                                            country: e.target.value
                                        });
                                    }}
                                >
                                    <option value="all">Pays</option>
                                    {
                                        countryList().getData().map((country, index) => (
                                            <option value={country.value} key={country.value + "/" + country.label}>
                                                {
                                                    country.label
                                                }
                                            </option>
                                        ))
                                    }
                                </select>
                                <div className="search_container_head_select_flag">
                                    <ReactCountryFlag countryCode={this.state.country}
                                        style={{
                                            fontSize: "2em",
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='search_container_head_filter'>
                            <div className='search_container_head_filter_list1'>
                                <div className='search_container_head_filter_list1_content'>
                                    <a className={'search_container_head_filter_list1_content_elt ' + (this.state.current_filter===0 ? " button-style-1" : "")} href='/'>
                                        <span>Tout</span>
                                    </a>
                                    {/* {
                                        this.categories.map((category, index) => (
                                            <a key={category.name + "/" + index} className={'search_container_head_filter_list1_content_elt ' + (this.state.current_filter===index+1 ? " button-style-1" : "")} href='/'>
                                                <span>{category.name}</span>
                                            </a>
                                        ))
                                    } */}
                                </div>
                            </div>
                            {/* <div className='search_container_head_filter_list2'>
                                <select className='search_container_head_filter_list2_content button-style-2'>
                                    <option value="all">Tous les filtres</option>
                                    {
                                        this.categories.map((category, index) => (
                                            <option key={category.name + "/" + index} className='search_container_head_filter_list2_content_elt'>
                                                {category.name}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div> */}
                        </div>

                        <div className='search_container_head_result'>
                            <div className='search_container_head_result_content'>
                                {/* <span>
                                    Environ 11 600 000 résultats (0,38 secondes) 
                                </span> */}
                            </div>
                        </div>
                    </form>

                    <div className='search_container_body'>
                        <div className='search_container_body_results'>
                            {
                                this.state.search_result.map((result, index) => (
                                    <div className='search_container_body_results_elt' key={result.name}>
                                        <div className='search_container_body_results_elt_top'>
                                            <a href='/' className='search_container_body_results_elt_top_link'>
                                                <div className='search_container_body_results_elt_top_link_imt'>
                                                    <div className='search_container_body_results_elt_top_link_imt_tx'>
                                                        <div className='search_container_body_results_elt_top_link_imt_tx_country'>
                                                            <h1>
                                                                {result.name}
                                                            </h1>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <br/>
                                        {
                                            result.laws.map((law, index) => (
                                                <div className='search_container_body_results_elt' key={law.name + "/" + result.name + "/" + index}>
                                                    <div className='search_container_body_results_elt_top'>
                                                        <a href='/' className='search_container_body_results_elt_top_link'>
                                                            <div className='search_container_body_results_elt_top_link_imt'>
                                                                <div className='search_container_body_results_elt_top_link_imt_im'>
                                                                    <img src={this.balance} alt='codelegg'/>
                                                                </div>
                                                                <div className='search_container_body_results_elt_top_link_imt_tx'>
                                                                    <div className='search_container_body_results_elt_top_link_imt_tx_title'>
                                                                        <h1>
                                                                            {law.name} no{law.number}
                                                                        </h1>
                                                                    </div>
                                                                    <div className='search_container_body_results_elt_top_link_imt_tx_describ'>
                                                                        <h1>
                                                                            portant code pénal
                                                                        </h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>

                                                    {
                                                        law.articles.map((article, index) => (
                                                            <div className='search_container_body_results_elt_content' key={article.number + "/" + index}>
                                                                <div className='search_container_body_results_elt_top'>
                                                                    <a href='/' className='search_container_body_results_elt_top_link'>
                                                                        <div className='search_container_body_results_elt_top_link_artl'>
                                                                            <h2>
                                                                                Article {article.number}
                                                                            </h2>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                                <p>
                                                                    {parse(article.text)}
                                                                </p>

                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            ))
                                        }
                                    </div>
                                ))
                            }
                            <div className='search_container_body_results_elt'>
                                
                                
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}


SearchView = connect(mapStateToProps, null)(SearchView);
export default SearchView;