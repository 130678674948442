import logo from './assets/logos/logo.png';
import balance from './assets/icons/e196.png';

class GLOBAL {
    constructor() {
        this.images = {
            logo_: logo,
            balance: balance,
        }
    }
}

export default GLOBAL;