import React from 'react';
import { connect } from 'react-redux';

/**----------------------------------------------------------------------------------------*/
/**Importation des composants de la page */
import countryList from 'react-select-country-list';
import ReactCountryFlag from "react-country-flag"


/**----------------------------------------------------------------------------------------*/
/** Importation des screens */



/**----------------------------------------------------------------------------------------*/
/**Importation du superScreen*/
import SuperScreen from '../class/SuperScreen';



/**----------------------------------------------------------------------------------------*/
/**Importation des styles de la page */
import './styles/home.style.scss';
import './responsive.style/home.responsive.style.scss';




/**----------------------------------------------------------------------------------------*/
/**Importation des images*/



import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user : state.User.user,
    };
}

class HomeView extends SuperScreen {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            country: null,
        }

        this.grid = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16];
        this.accessStore = new AccessStore( this.props.dispatch);
    }

    componentDidMount() {
        // let countries = [
            
        // ];
        // countryList().getData().map((country, index) => {
        //     countries.push({
        //         name: country.label,
        //         code: country.value
        //     });
        // });

        // let data = new FormData();
        // data.append("data", JSON.stringify(countries));
        // this._addArticle(data, (response) => {
        //     console.log(response);
        // }, (e) => {
        //     console.log(e);
        // });
    }
   
    render() {
        return (
            <>
                {
                    super.render()
                }
                <div className='container'>
                    <div className='container_logo'>
                        <img src={this.logo} alt='codelegg'/>
                    </div>

                    <div className='container_search'>
                        <form className='container_search_form' method='GET' action='/search:' role='search'>
                            <div className='container_search_form_input'>
                                <div className='container_search_form_input_svg'>
                                    <svg fill="currentColor" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path></svg>
                                </div>
                                <div className='container_search_form_input_balise'>
                                    <input  
                                        jsaction="paste:puy29d;" 
                                        id="APjFqb" 
                                        maxLength="2048" 
                                        name="search"
                                        rows="1" 
                                        aria-activedescendant="" 
                                        aria-autocomplete="both" 
                                        aria-controls="Alh6id" 
                                        aria-expanded="false"  
                                        aria-owns="Alh6id"
                                        autoCapitalize="off"
                                        autoComplete="off" 
                                        autoCorrect="off" 
                                        autoFocus={true}
                                        role="combobox"
                                        spellCheck="false" 
                                        title="Rechercher" 
                                        type="search" 
                                        aria-label="Rech." 
                                        data-ved="0ahUKEwjRkpuS4rD-AhXOTaQEHbj9CswQ39UDCAQ"
                                    />
                                    {/* <textarea type='search' name='search'></textarea> */}
                                </div>
                            </div>

                            <div className='container_search_form_button'>
                                <button className='button-style-1'>
                                    Rechercher
                                </button>
                                <div className='container_search_form_button_select'>
                                    <select name='country' className='button-style-2'
                                        onChange={(e) => {
                                            this.setState({
                                                country: e.target.value
                                            });
                                        }}
                                    >
                                        <option value="all">Pays</option>
                                        {
                                            countryList().getData().map((country, index) => (
                                                <option value={country.value} key={country.value + "/" + country.label}>
                                                    {
                                                        country.label
                                                    }
                                                </option>
                                            ))
                                        }
                                    </select>
                                    <div className='container_search_form_button_select_flag'>
                                        <ReactCountryFlag countryCode={this.state.country}
                                            style={{
                                                fontSize: "2em",
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </>
        )
    }
}


HomeView = connect(mapStateToProps, null)(HomeView);
export default HomeView;